<template>
  <div class="ttsl">
    <h3 class="ttime">
      {{ formattedTime }}
    </h3>
    <dv-decoration-8 :reverse="true" style="width: 100%; height: 7vh" />
  </div>
</template>
  
  <script>
export default {
  data() {
    return {
      formattedTime: "",
    };
  },
  mounted() {
    this.getCurrentTime();
    setInterval(() => {
      this.getCurrentTime();
    }, 1000);
  },
  methods: {
    getCurrentTime() {
      const date = new Date();
      const year = date.getFullYear();
      const month = this.formatNumber(date.getMonth() + 1);
      const day = this.formatNumber(date.getDate());
      const hours = this.formatNumber(date.getHours());
      const minutes = this.formatNumber(date.getMinutes());
      const seconds = this.formatNumber(date.getSeconds());

      this.formattedTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
    formatNumber(value) {
      return value < 10 ? `0${value}` : value;
    },
  },
};
</script>
  
<style lang="scss" scoped>
.ttsl{
  width: 100%;
  height: 100%;
  .ttime {
    width: 100%;
    height: 14px;
    font-size: 1.2vw;
    font-weight: 500;
    word-spacing: 10px;
    position: relative;
    top: -3vh;
    left: 10px;
}
}


</style>
  
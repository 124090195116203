<template>
  <div class="box">
    <div class="title">胸 痛 病 因</div>
    <div class="content">
      <div id="chartContain" style="width: 100%; height: 300px"></div>
      <!-- <dv-active-ring-chart :config="config" style="width:200px;height:200px" /> -->
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts/core'
const colors = [
  '#5470C6',
  '#91CC75',
  '#EE6666',
  '#5470C6',
  '#91CC75',
  '#EE6666',
  '#5470C6',
]
import request from '../config/require'

export default {
  data() {
    return {
      myChart: {},
      options: {
        // color: colors,
        title: {
          text: '',
          left: 'center',
          textStyle: {
            color: '#999',
            fontWeight: 'normal',
            fontSize: 14,
          },
        },
        tooltip: {
          trigger: 'item',
        },
        legend: {
          // orient: "vertical",
          orient: 'horizontal',
          x: 'left',
          y: 'bottom',
          // left: "right",
          textStyle: {
            color: '#fff',
          },
        },
        series: [
          {
            name: '',
            type: 'pie',
            radius: '60%', // 设置饼状图大小，100%时，最大直径=整个图形的min(宽，高)
            center: ['50%', '42%'], // 设置饼状图位置，第一个百分数调水平位置，第二个百分数调垂直位置
            itemStyle: {
              borderColor: '#fff',
              borderWidth: 1,
              // color: '#fff',
            },

            data: [
              { value: 1048, name: 'Search Engine' },
              { value: 735, name: 'Direct' },
              { value: 580, name: 'Email' },
              { value: 484, name: 'Union Ads' },
              { value: 300, name: 'Video Ads' },
            ],
            label: {
              formatter: '{b} ({c})',
            },

            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(1, 1, 1, 0.5)',
              },
            },
          },
        ],
      },
    }
  },
  mounted() {
    setInterval(() => {
      this.getData()
    }, 2 * 1 * 60 * 1000)
    this.getData()
  },
  methods: {
    getData() {
      //
      //当前使用的 api (线上数据) || user(本地数据)
      //
      request.get('getStatisticsNumByDiagnosis').then(async (res) => {
        // console.log('000');
        console.log('胸痛病因', res)
        if (res && res?.data?.code === '00000') {
          if (res.data.data.datas.length > 0) {
            this.options.series[0].data = await res.data.data.datas
          }
        }
        this.options.series[0].data.forEach((item) => {
          item.label = {
            color: '#fff',
          }
        })
        this.initChart()
      })
    },
    initChart() {
      this.myChart = echarts.init(document.getElementById('chartContain'))
      console.log(this.options.series[0].data)
      this.myChart.setOption(this.options)
    },
  },
}
</script>

<style scoped lang="scss">
.box {
  position: relative;
  height: 100%;
  .title {
    position: absolute;
    top: 5%;
    left: 50%;
    transform: translateX(-50%);
    font-size: 20px;
    text-align: center;
  }
  .content {
    width: 100%;
    position: absolute;
    top: 59%;
    left: 52%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
  }
}
</style>

<template>
  <div>
    <div class="lftitle">
      <div class="blank"></div>
      <div class="lftt">
        <h3>{{ weather.city }}</h3>
        <dv-decoration-4 style="width: 5px; height: 40px" />
        <dv-decoration-2 :reverse="true" style="width: 5px; height: 40px" />
        <img :src="imgSrc" alt="" srcset="" style="border-radius: 6px" />
        <p>{{ weather.wea }}</p>
        <p>{{ weather.tem2 }}°C~{{ weather.tem1 }}°C</p>
      </div>
      <dv-decoration-8
        style="width: 100%; height: 7vh"
        backgroundColor="blue"
      ></dv-decoration-8>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'
export default {
  data() {
    return {}
  },
  computed: {
    ...mapState(['weather']),
    imgSrc() {
      return require(`../assets/img/${this.weather.wea_img}.png`)
    },
  },
  mounted() {
    this.watchTime()
  },
  watch: {
    weather: {
      handler() {
        if (this.weather.date) {
          return
        }
        this.$store.dispatch('getWeather')
      },
      immediate: true,
    },
  },
  methods: {
    watchTime() {
      setInterval(() => {
        const weatherDate = new Date(this.weather.date)
        weatherDate.setHours(23, 59, 59, 999)
        const weatherTime = weatherDate.getTime()

        const currentTime = Date.now()

        if (currentTime > weatherTime) {
          this.$store.dispatch('getWeather')
        }
      }, 1000)
    },
  },
}
</script>

<style lang="scss" scoped>
.lftitle {
  height: 14px;
  position: relative;
  .blank {
    width: 100%;
    height: 14px;
  }
  .lftt {
    width: 100%;
    height: 14px;
    display: flex;
    align-items: center;

    position: absolute;
    top: 13px;
    left: 100px;
    h3 {
      margin-right: 10px;
      padding-top: 10px;
    }

    img {
      width: 30px;
      height: 30px;
      margin-left: 10px;
      // padding-top: 10px;
    }
    P {
      padding-top: 10px;
      line-height: 14px;
      margin-left: 5px;
      font-size: 16px;
    }
  }
}
</style>

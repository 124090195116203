<template>
  <div class="ttle">
    <h1>胸 痛 中 心 可 视 化 平 台</h1>
    <dv-decoration-5 class="d5" style="width: 100%; height: 50px" />
  </div>
</template>

<script>
export default {
  name: 'Titttle',
  components: '',
  data() {
    return {}
  },
  created() {},
  mounted() {},
  methods: {},
}
</script>

<style scoped lang="scss">
.ttle {
  // width: 100%;
  // height: 100%;
  display: flex;
  flex-direction: column;

  h1 {
    font-size: 40px;
    color: #fff;
    font-weight: 500;
    font-family: 'Lucida Grande', 'Lucida Sans Unicode', Arial, Helvetica,
      sans-serif;
  }
  .d5 {
    position: relative;
    top: -15px;
  }
}
</style>

<template>
    <div class="box">
        <div class="title">胸 痛 病 患 来 院 方 式</div>
        <div class="content">
            <div class="list">
                <div class="item" 
                 v-for="(item,index) in list" :key="index">
                    <dv-decoration-9 style="width:70px;height:70px;">{{ item.value }}人</dv-decoration-9>
                    <div style="text-align: center; padding-top: 5px;">{{ item.namee }}</div>
                 </div>
            </div>
        </div>
    </div>
</template>

<script>
import request from '../config/require'
export default {
  name: 'RingChart',
    components: '',
    data() {
        return {
            list:[
                {
                    id:1, //1
                    value:0,
                    namee:'急救来院'
                },
                {
                    id:2, //2
                    value:0,
                    namee:'基层来院'
                }, 
                {
                    id:3, //3
                    value:0,
                    namee:'自行来院'
                },
                {
                    id:4,  // 4
                    value:0,
                    namee:'院内发病'
                }
            ],
            config:{
                data: []
            }
        };
    },
    created() {
        

    },
    mounted() {
        // 获取数据
        this.getData();
        setInterval(() => {
            this.getData();
        }, 1 * 60 * 1000);
        // this.getData()
    },
    methods: {
        // get data
        getData(){
            //https://cpcdfp.iyicifang.com/cpcdfillin/zyApi/
            // getNumberOfPatientsByLyfs
            //
            //当前使用的 api (线上数据) || user(本地数据)   
            //
            
            request.get('getNumberOfPatientsByLyfs').then(res => {
                console.log(res);
                if (res && res?.data?.code === "00000") {
                    console.log(res.data)
                    let data =  res.data.data;
                    let sortData =[]
                    for (let i = 0; i < data.length; i++) {
                        for (let j = 0; j < data.length; j++) {
                            if (data[j].code == i+1) {
                                sortData[i] = data[j]
                            }
                        }
                    }
                    console.log(sortData);
                    sortData.forEach((item,index) => {
                        this.list[index].value = item.value;
                    })
                }
            })
        }
    },
    watch:{
        
    }
};
</script>

<style scoped lang="scss">
.box{
    height: 100%;
    .title{
        width: 100%;
        height: 20%;
        padding-top: 20px;
        font-size: 20px;
        text-align: center;

    }
    .content{
        width: 100%;
        height: 80%;
        position: relative;
        
        .list{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            display: flex;
            align-items: center;
            justify-content: space-around;
            .item{
                display: flex;
                flex-direction: column;
                margin: 5px;
                padding: 4px 10px;
                cursor: pointer;
                // background-color: rgba(255,255,255,0.2);
                
            }
        }
    }
}
</style>

